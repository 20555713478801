import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <div className="header">
      <div>
        <img
          src="shankys-header.png"
          alt="Right"
          className="header-image right-image"
        />
      </div>
    </div>
  );
};

export default Header;
