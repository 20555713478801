import React from "react";
import "./Footer.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Footer = () => {
  const phoneNumber = "+918318277609";

  return (
    <a
      href={`https://wa.me/${phoneNumber}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="footer">
        <div className="footer-internal">
          Reach Us on
          <WhatsAppIcon />
        </div>
      </div>
    </a>
  );
};

export default Footer;
