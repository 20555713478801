import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <div className="home-container">
      <div className="home-internal-div">
        <div className="delivery-button">
          <a
            href="https://shanky.dotpe.in/store/1/delivery"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="delivery-btn.png"
              alt="delivery-btn"
              className="delivery-button-img"
            />
          </a>
        </div>
        <div className="dine-in-button">
          <a
            href="https://shanky.dotpe.in/store/1/1/999"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="dinein-btn.png"
              alt="dinein-btn"
              className="dinein-button-img"
            />
          </a>
        </div>
        <div className="takeaway-button">
          <a
            href="https://shanky.dotpe.in/store/1/takeaway"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="takeaway-btn.png"
              alt="takeaway-btn"
              className="takeaway-button-img"
            />
          </a>
        </div>
        <div className="pickup-button">
          <a
            href="https://shanky.dotpe.in/store/1/pickup"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="pickup-btn.png"
              alt="pickup-btn"
              className="pickup-button-img"
            />
          </a>
        </div>
        <img src="main.png" alt="Home page" className="home-image " />
      </div>
    </div>
  );
};

export default Home;
